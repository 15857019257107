/* curtains canvas container */

.curtains-canvas {
  position: fixed;
  top: -20vh;
  left: 50vw;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

/* basic plane */
.curtains-plane {
  width: 80vw;
  height: 80vh;
  position: fixed;
  top: 0;
  left: 50vw;
  z-index: -1;
  background-blend-mode: color;

  img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.disabled-image {
      display: block;
    }

    &.mouse-opacity-image {
      display: block;
      object-fit: cover;
    }
  }
}

@media (max-width: 798px) {
  .curtains-canvas {
    top: -250px;
    left: 0;
    position: absolute;
  }

  .curtains-plane {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: -250px;
  }

  #webgl-curtains-page {
    overflow: hidden;
  }
}
